/*import font*/
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
body {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
/*header-wrap*/
.header-wrap {
}
/*hero-section-wrap*/
.hero-section-wrap {
  margin-top: -94px;
  background-image: url("../img/bg-home.jpg");
  background-repeat: no-repeat;
  background-position: top center;
   background-color: #EB683A;
}
.section-services {
  background-image: url("../img/bg-service.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  
}
.section-client {
  background-image: url("../img/bg-client.jpg");
  background-repeat: no-repeat;
  background-position: top center;
}
.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  overflow: hidden;
}
.contact-section {
  background-image: url("../img/bg-contact.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.swiper {
  width: 100%;
  height: 600px;
  overflow: visible;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  background: rgb(0, 0, 0);
  transition: all 0.3s ease;
  img {
    opacity: 0.7;
  }

  &.swiper-slide-active {
    color: #fff;
    background: linear-gradient(45deg, rgb(246, 146, 89), rgb(241, 105, 117));
    transform: scale(1.2);
    z-index: 2;
    img {
      opacity: 1;
    }
  }
}
